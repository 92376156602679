import * as React from "react"

const styles = {
  dot1: {
    animationDelay: "-0.32s",
  },
  dot2: {
    animationDelay: "-0.16s",
  },
}

const LoadingScreen: React.FC = () => {
  return (
    <div className="vh-100 wh-100 d-flex justify-content-center align-items-center fixed-top bg-light">
      <div className="spinner-grow" style={styles.dot1} role="status"></div>
      <div className="spinner-grow" style={styles.dot2} role="status"></div>
      <div className="spinner-grow" role="status"></div>
    </div>
  )
}

export { LoadingScreen }
