import * as React from "react"
import { Link } from "gatsby"

interface ArticleProps {
  article: {
    slug: string
    title: string
  }
}

const Article: React.FC<ArticleProps> = ({ article }) => {
  return (
    <>
      <div className="col mb-3">
        <Link to={`/faq${article.slug}`}>
          <div className="card">
            <div className="card-body">
              <p className="card-text mb-0">{article.title}</p>
            </div>
          </div>
        </Link>
      </div>
    </>
  )
}

export { Article }
