import * as React from "react"

type ReviewProps = {
  title: string
  body: string
}

const Review: React.FC<ReviewProps> = ({ title, body }) => {
  return (
    <div style={{ backgroundColor: "#f6f6f6" }} className="rounded-lg p-4 mb-4">
      <h5 className="text-dark">{title}</h5>
      <div>
        <span className="rating">★★★★★</span>
      </div>
      <p>{body}</p>
    </div>
  )
}

export { Review }
