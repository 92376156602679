import * as React from "react"
import { Helmet } from "react-helmet"
import { Footer } from ".."

import NewLogoWhite from "../../assets/papelzinho-new-logo-white.svg"
import SmallLogo from "../../assets/small-logo.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { analyticsTrack } from "../../utils/analytics"
import { useAppSelector } from "../../app/hooks"

import "./layout.scss"

interface LayoutProps {
  children: React.ReactNode
  noHeader?: boolean
}

const Layout: React.FC<LayoutProps> = (props) => {
  const language = useAppSelector((state: any) => state.language.config)
  const texts = language.Header

  const [isMobile, setIsMobile] = React.useState(false)

  React.useEffect(() => {
    const isMobileDevice = () => {
      return window.innerWidth < 768
    }

    setIsMobile(isMobileDevice())
  }, [])

  return (
    <>
      <main>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
            integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2"
            crossOrigin="anonymous"
          />
        </Helmet>
        {!props.noHeader && (
          <header className="header gradient-bg py-4">
            <img
              src={isMobile ? SmallLogo : NewLogoWhite}
              alt="Papelzinho logo"
              height={isMobile ? 40 : 30}
              onClick={() => (window.location.href = "/")}
              className="logo"
            />

            <OutboundLink
              className="shop-menu"
              href="https://loja.papelzinho.com"
              target="_blank"
              onClick={() => {
                analyticsTrack(
                  "loja-papelzinho-home",
                  "clique",
                  "botão-navibar"
                )
              }}
            >
              {texts.store}
            </OutboundLink>
          </header>
        )}

        <main className="pb-5">{props.children}</main>
        <Footer />
      </main>
    </>
  )
}

export { Layout }
