import * as React from "react"
import { analyticsTrack } from "../utils"
import Bullet from "../assets/icons/bullet.svg"

import { useAppSelector } from "../app/hooks"

const ParticipantForm = ({
  participant,
  showToast,
  setShowToast,
  toastMessage,
  setToastMessage,
  toastStatus,
  setToastStatus,
}) => {
  const [option1, setOption1] = React.useState(
    participant.options !== undefined ? participant.options[0] : ""
  )
  const [option2, setOption2] = React.useState(
    participant.options !== undefined ? participant.options[1] : ""
  )
  const [option3, setOption3] = React.useState(
    participant.options !== undefined ? participant.options[2] : ""
  )
  const [comment, setComment] = React.useState(
    participant.comments !== undefined ? participant.comments : ""
  )
  const [editParticipantStatus, setEditParticipantStatus] = React.useState(
    option1 !== "" || option2 !== "" || option3 !== "" || comment !== ""
  )
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!editParticipantStatus) return

    if (loading) {
      return
    }
    setLoading(true)

    const data = {
      groupId: participant.groupId,
      participantId: participant.id,
      options: [option1, option2, option3],
      comments: comment,
    }

    fetch(
      `https://80c14gw0pj.execute-api.us-east-2.amazonaws.com/Prod/participant/${participant.id}`,
      {
        method: "PUT",
        body: JSON.stringify(data),
      }
    )
      .then((response) => response.text())
      .then(async () => {
        setLoading(false)
        setToastStatus("success")
        setToastMessage("Sua lista de presentes foi salva!")
        setShowToast(true)
      })
      .catch(() => {
        setLoading(false)
        setToastStatus("error")
        setToastMessage(
          "Não foi possível salvar sua lista de presentes, por favor tente novamente."
        )
        setShowToast(true)
      })
  }

  const language = useAppSelector((state) => state.language.config)
  const texts = language.participantGiftList

  return (
    <div className="participantForm">
      <form className="h-100 shadow" onSubmit={handleSubmit}>
        <div
          className="participantCard"
          style={
            editParticipantStatus
              ? {
                  background: "var(--BG-Color, #FFEDEB)",
                  border:
                    "1px solid var(--BG-Transparency, rgba(255, 73, 58, 0.10))",
                  boxShadow: "4px 4px 16px 2px rgba(155, 47, 28, 0.16)",
                }
              : {}
          }
        >
          <div className="cardTexts">
            <h3 className="participantCardTitle">{texts.myList}</h3>
            <h6 className="participantCardOptions">{texts.giftOptions}</h6>
          </div>

          {!editParticipantStatus ? (
            <>
              <div className="form-group">
                <textarea
                  className="participantFormInput"
                  placeholder={texts.optionsPlaceHolders[0]}
                  onInput={(e) => setOption1(e.target.value)}
                  rows={1}
                  defaultValue={option1 ? option1 : ""}
                ></textarea>
              </div>
              <div className="form-group">
                <textarea
                  className="participantFormInput"
                  placeholder={texts.optionsPlaceHolders[1]}
                  rows={1}
                  onInput={(e) => setOption2(e.target.value)}
                  defaultValue={option2 ? option2 : ""}
                ></textarea>
              </div>
              <div className="form-group">
                <textarea
                  className="participantFormInput"
                  placeholder={texts.optionsPlaceHolders[2]}
                  rows={1}
                  onInput={(e) => setOption3(e.target.value)}
                  defaultValue={option3 ? option3 : ""}
                ></textarea>
              </div>
              <h6 className="mb-3">{texts.observations}</h6>
              <div className="form-group">
                <textarea
                  className="participantFormInput"
                  placeholder={texts.optionsPlaceHolders[3]}
                  style={{ height: "100px" }}
                  rows={5}
                  onInput={(e) => setComment(e.target.value)}
                  defaultValue={comment ? comment : ""}
                ></textarea>
              </div>
            </>
          ) : (
            <>
              <div className="formGroups">
                <div className="form-group">
                  <img src={Bullet} alt="" />
                  <div className="participantOption">
                    {option1 || texts.optionsPlaceHolders[0]}
                  </div>
                </div>
                <div className="form-group">
                  <img src={Bullet} alt="" />

                  <div className="participantOption">
                    {option2 || texts.optionsPlaceHolders[1]}
                  </div>
                </div>
                <div className="form-group">
                  <img src={Bullet} alt="" />
                  <div className="participantOption">
                    {option3 || texts.optionsPlaceHolders[2]}
                  </div>
                </div>
                <h6 className="mb-3">{texts.observations}</h6>
                <div className={`form-group} `}>
                  <div className="participantOption">
                    {comment || texts.optionsPlaceHolders[3]}
                  </div>
                </div>
              </div>
            </>
          )}

          {!editParticipantStatus ? (
            <div className="shopButtonContainer">
              <button
                type="submit"
                className="papelzinhoShopButton"
                style={{ minHeight: "64px" }}
                onClick={() => {
                  analyticsTrack(
                    "home-preenchimento-lista-presentes",
                    "clique",
                    "formulário-minha-lista-botão-salvar"
                  )

                  setEditParticipantStatus(true)
                }}
              >
                {loading ? (
                  <span>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {texts.saving}
                  </span>
                ) : (
                  texts.save
                )}
              </button>
            </div>
          ) : (
            <div className="shopButtonContainer">
              <button
                className="papelzinhoShopButton"
                style={{
                  minHeight: "64px",
                }}
                onClick={() => {
                  analyticsTrack(
                    "home-preenchimento-lista-presentes",
                    "clique",
                    "botão-editar"
                  )

                  setEditParticipantStatus(false)
                }}
              >
                {texts.edit}
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export { ParticipantForm }
