import React, { FC } from "react"
import { analyticsTrack } from "../utils"
import { StoreIcon } from "../assets/icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Bullet from "../assets/icons/bullet.svg"
import Emoji from "../assets/icons/emoji.svg"

import { useAppSelector } from "../app/hooks"

const styles = {
  storeButton: {
    minHeight: "64px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    gap: "16px",
  },
  storeIcon: {
    width: "30px",
    marginRight: "20px",
  },

  otherParticipant: {
    height: "534px",
  },
}
interface ParticipantProps {
  participant: {
    name: string
    options: []
    comments: string
  }
  isSecretFriend?: boolean
}

const Participant: FC<ParticipantProps> = ({
  participant,
  isSecretFriend = false,
}) => {
  const language = useAppSelector((state) => state.language.config)
  const texts = language.participantGiftList

  return (
    <div className="participantForm">
      <form>
        <div
          className="participantCard"
          style={isSecretFriend ? {} : styles.otherParticipant}
        >
          <div className="cardTexts">
            <h4 className="participantCardTitle">
              {isSecretFriend ? texts.whatMyFriendWants : participant?.name}
            </h4>
            {participant?.options !== undefined &&
            participant?.options.length >= 1 &&
            (participant?.options[0] ||
              participant?.options[1] ||
              participant?.options[2]) ? (
              <h5 className="participantCardOptions ">{texts.giftOptions}</h5>
            ) : (
              ""
            )}
          </div>

          {participant?.options !== undefined &&
          participant?.options.length >= 1 &&
          (participant?.options[0] ||
            participant?.options[1] ||
            participant?.options[2]) ? (
            <>
              <div className="form-group">
                <img src={Bullet} alt="" />
                <div className="participantOption">
                  {participant?.options.length >= 1
                    ? participant?.options[0]
                    : texts.optionsPlaceHolders[0]}
                </div>
              </div>
              <div className="form-group">
                <img src={Bullet} alt="" />

                <div className="participantOption">
                  {participant?.options.length >= 1
                    ? participant?.options[1]
                    : texts.optionsPlaceHolders[1]}
                </div>
              </div>
              <div className="form-group">
                <img src={Bullet} alt="" />
                <div className="participantOption">
                  {participant?.options.length >= 1
                    ? participant?.options[2]
                    : texts.optionsPlaceHolders[2]}
                </div>
              </div>
              <h6 className="mb-3">Observações</h6>
              <div className={`form-group ${isSecretFriend && "mb-3"} `}>
                <div className="participantOption">
                  {participant?.comments || texts.optionsPlaceHolders[3]}
                </div>
              </div>

              <div className="shopButtonContainer">
                {isSecretFriend && (
                  <OutboundLink
                    href="https://loja.papelzinho.com/"
                    className="papelzinhoShopButton"
                    style={styles.storeButton}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      analyticsTrack(
                        "loja-papelzinho-home",
                        "clique",
                        "formulário-amigo-loja papelzinho"
                      )
                    }}
                  >
                    {texts.store}
                  </OutboundLink>
                )}
              </div>
            </>
          ) : (
            <div className="participantDidntFillContainer">
              <div className="participantDidntFillContent">
                <img src={Emoji} alt="" width="40" height="40" />

                <p className="participantDidntFillTitle">
                  {texts.didntFillGiftList.header}
                </p>
                <div className="participantDidntFillSuggestion">
                  {texts.didntFillGiftList.body}
                </div>
              </div>

              <div className="participantDidntFillButtonContainer">
                {isSecretFriend && (
                  <OutboundLink
                    href="https://loja.papelzinho.com/"
                    className="papelzinhoShopButton"
                    style={styles.storeButton}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      analyticsTrack(
                        "loja-papelzinho-home",
                        "clique",
                        "formulário-amigo-loja papelzinho"
                      )
                    }}
                  >
                    {texts.store}
                  </OutboundLink>
                )}
              </div>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}

export { Participant }
