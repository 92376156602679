import * as React from "react"
import RedLogo from "../assets/papelzinho-colorful-logo.svg"
import PapelzinhoColorfulLogo from "../assets/papelzinho-colorful-logo.svg"
import DownloadApple from "../assets/download-apple.svg"
import DownloadPlay from "../assets/download-play.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { analyticsTrack } from "../utils/analytics"
import { useAppSelector } from "../app/hooks"

const Footer: React.FC = () => {
  const languages = useAppSelector((state: any) => state.language.config)
  const texts = languages.Footer

  return (
    <>
      <footer className="footer desktop">
        <div className="info">
          <img
            src={PapelzinhoColorfulLogo}
            alt="Papelzinho"
            width={232}
            height={40}
          />

          <div className="footerLinksContainer">
            <a
              href="https://loja.papelzinho.com"
              target="blank"
              className="footerLink"
            >
              {texts.store}
            </a>

            <div className="line"></div>

            <a
              href="https://loja.papelzinho.com/about"
              target="blank"
              className="footerLink"
            >
              {texts.aboutUs}
            </a>

            <div className="line"></div>

            <a
              href="https://papelzinho.com.br/faq"
              target="_blank"
              className="footerLink"
              rel="noreferrer"
            >
              FAQ
            </a>
          </div>
        </div>

        <div className="downloadAppContainer">
          <div className="downloadAppOptions">
            <p className="downloadAppText">{texts.download}</p>

            <div className="downloadImages">
              <a
                href="https://itunes.apple.com/br/app/papelzinho/id1133660909?mt=8"
                target="blank"
              >
                <img src={DownloadApple} alt="" width="163" height="53" />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=kaiquedamato.com.papelzinho"
                target="blank"
              >
                <img src={DownloadPlay} alt="" width="163" height="53" />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <footer className="footer mobile">
        <img src={RedLogo} alt="Papelzinho logo" />
        <a className="footerLink" href="https://loja.papelzinho.com">
          Loja papelzinho
        </a>

        <a
          target="blank"
          href="https://loja.papelzinho.com/about"
          className="footerLink"
        >
          Sobre nós
        </a>

        <a
          className="footerLink"
          target="_blank"
          href="https://papelzinho.com.br/faq"
          rel="noreferrer"
        >
          FAQ
        </a>

        <div className="downloadAppContainer">
          <div className="downloadAppOptions">
            <p className="downloadAppText">Baixe o App</p>

            <div className="downloadImages">
              <OutboundLink
                href="https://itunes.apple.com/br/app/papelzinho/id1133660909?mt=8"
                target="_blank"
                onClick={() => {
                  analyticsTrack(
                    "home-download-app",
                    "clique",
                    "botão-app-store"
                  )
                }}
              >
                <img
                  src={DownloadApple}
                  alt="Símbolo de download na Apple."
                  width="163"
                  height="52"
                />
              </OutboundLink>

              <OutboundLink
                href="https://play.google.com/store/apps/details?id=kaiquedamato.com.papelzinho"
                target="_blank"
                onClick={() => {
                  nalyticsTrack(
                    "home-download-app",
                    "clique",
                    "botão-google-play"
                  )
                }}
              >
                <img
                  src={DownloadPlay}
                  alt="Símbolo de download na Play Store."
                  width="163"
                  height="52"
                />
              </OutboundLink>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export { Footer }
