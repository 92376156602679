import * as React from "react"
import { analyticsTrack } from "../../utils"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import MeliuzImgEaster from "../../assets/img/popup-meliuz-easter.png"
import Confete from "../../assets/confete.svg"
import Close from "../../assets/close.svg"
import BigEyes from "../../assets/big-eyes.svg"
import ShopIcon from "../../assets/shop-icon.svg"

import { useAppSelector } from "../../app/hooks"

import "./popup.scss"

const styles = {
  wrapper: {
    backgroundColor: "rgb(0, 0, 0, 0.50)",
  },
  link: {
    width: "85%",
    textDecoration: "none",
    cursor: "pointer",
  },
  btnNotBuy: {
    fontSize: "12px",
  },
}

type PopupProps = {
  message: string
  action2: () => void
  goToGiftList: () => void
  showSecretFriend: boolean
  personId: any
}

const Popup: React.FC<PopupProps> = ({
  message,
  action2,
  goToGiftList,
  showSecretFriend,
  personId,
}) => {
  const language = useAppSelector((state) => state.language.config)
  const texts = language.popUp

  const updateParticipant = async (participantId: any) => {
    try {
      const response = await fetch(
        `https://u39ua4g3tg.execute-api.us-east-2.amazonaws.com/Prod/participant/${participantId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            raffleStatus: "read",
          }),
        }
      )

      if (!response.ok) {
        throw new Error("Failed to update participant")
      }

      const data = await response.json()
      console.log("Updated participant:", data)
    } catch (error) {
      console.error("Error updating participant:", error)
    }
  }
  
  const [show, setShow] = React.useState(showSecretFriend)

  return (
    <>
      {show ? (
        <>
          <div
            className="vh-100 wh-100 d-flex justify-content-center align-items-center fixed-top"
            style={styles.wrapper}
          >
            <div className="popup__content-container bg-white d-flex flex-column justify-content-flex-start align-items-center text-center">
              <div className="position-relative decorationPopup">
                <img
                  src={Confete}
                  alt="Banner Méliuz: Compre o presente do seu amigo secreto e ganhe cashback com o Méliuz."
                  className="popup__size img-fluid"
                />

                <button
                  className="closeButton"
                  style={styles.btnNotBuy}
                  onClick={action2}
                >
                  <img src={Close} alt="Fechar" className="popupClose" />
                </button>

                <p className="introducingFriend">{texts.yourFriendIs}.</p>

                <div className="secretFriendContainer">
                  <style>
                    {`
                      .friendIntroduced {
                        ${
                          message.length > 97
                            ? "font-size: 24px !important"
                            : ""
                        }
                      }
                    `}
                    {`
                      @media (max-width: 768px) {
                        .friendIntroduced {
                          ${
                            message.length > 40
                              ? "font-size: 20px !important"
                              : ""
                          }
                        }
                      }
                    `}
                    {`
                      @media (max-width: 768px) {
                        .friendIntroduced {
                          ${
                            message.length > 63
                              ? "font-size: 16px !important"
                              : ""
                          }
                        }
                      }
                    `}
                    {`
                      @media (max-width: 768px) {
                        .friendIntroduced {
                          ${
                            message.length > 117
                              ? "font-size: 12px !important"
                              : ""
                          }
                        }
                      }
                    `}
                  </style>

                  <p className="popup__secret-friend font-weight-bold w-100 friendIntroduced">
                    {message}
                  </p>
                </div>
              </div>

              <div className="popUpButtons">
                <div className="buyGiftButton bg-white d-flex flex-column align-items-center">
                  <OutboundLink
                    href="https://loja.papelzinho.com"
                    target="_blank"
                    className="popUpButton buyGift"
                    style={styles.link}
                    rel="noopener noreferrer"
                    onClick={() => {
                      analyticsTrack(
                        "loja-papelzinho-modal-revelação",
                        "clique",
                        "botão-comprar-presente"
                      )
                    }}
                  >
                    {texts.buyGift}
                  </OutboundLink>
                </div>

                <div className="giftListButton bg-white d-flex flex-column align-items-center">
                  <OutboundLink
                    href="#"
                    className="popUpGiftListButton"
                    style={styles.link}
                    rel="noopener noreferrer"
                    onClick={() => {
                      goToGiftList()
                    }}
                  >
                    {texts.giftList}
                  </OutboundLink>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="vh-100 wh-100 d-flex justify-content-center align-items-center fixed-top"
            style={styles.wrapper}
          >
            <div className="popup__content-container bg-white d-flex flex-column justify-content-center align-items-center text-center protectFriendContainer">
              <div className="closeContainer">
                <button
                  className="secretFriendCloseButton"
                  style={styles.btnNotBuy}
                  onClick={action2}
                >
                  <img src={Close} alt="Fechar" className="popupClose" />
                </button>
              </div>

              <p className="warningToShowSecretFriendText">{texts.warning}</p>

              <div className="bigEyesContainer">
                <img src={BigEyes} alt="Olhos gigantes" className="bigEyes" />
              </div>
              <div className="buyGiftButton bg-white d-flex flex-column align-items-center pt-3 pb-3 revealFriendButtonContainer">
                <button
                  className="popUpButton revealSecretFriendButton"
                  onClick={() => {
                    setShow(true), updateParticipant(personId)
                  }}
                >
                  {texts.seeWhoIDrew}
                </button>

                <div className="">
                  <OutboundLink
                    href="https://loja.papelzinho.com"
                    target="_blank"
                    className="papelzinhoShopLink"
                    onClick={() => {
                      analyticsTrack(
                        "loja-papelzinho-modal-ninguem-espiando",
                        "clique",
                        "botão-loja-papelzinho"
                      )
                    }}
                  >
                    {texts.store}
                    <img src={ShopIcon} alt="Ícone de carrinho" />
                  </OutboundLink>

                  <div className="underline"></div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export { Popup }
