import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const analyticsTrack = (
  category: string,
  action: string,
  label: string
): void =>
  trackCustomEvent({
    category: category,
    action: action,
    label: label,
  })

export { analyticsTrack }
